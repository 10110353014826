import React from 'react'
import BounceLoader from "react-spinners/BounceLoader";
import '../styles/LoadingScreen.css'

export default function LoadingScreen() {
    return (
        <div className="loading__cover">
            <BounceLoader color='var(--accent-200)' loading={true} size={100} />
            <div className="loading__text">Loading...</div>
        </div>
    )
}

import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Value from '../components/Value'
import { useMediaQuery } from 'react-responsive'
import { useParallax } from 'react-scroll-parallax'
import { faDumbbell, faLayerGroup, faLightbulb } from '@fortawesome/free-solid-svg-icons'
import { faCircle as faRegularCircle } from '@fortawesome/free-regular-svg-icons'
import '../styles/About.css'
import LoadingScreen from '../components/LoadingScreen'

export default function About() {
    const [drawerOpen, setDrawerOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const [loadedImages, setLoadedImages] = useState(0)

    const totalImages = 4

    const isTablet = useMediaQuery({ query: '(max-width: 964px)' })
    const isMobile = useMediaQuery({ query: '(max-width: 822px)' })

    const parallaxRef1 = useParallax({
        easing: 'easeOutQuad',
        scale: isMobile ? [1, 1.1] : [1, 1],
    }).ref

    const parallaxRef2 = useParallax({
        easing: 'easeOutQuad',
        scale: isMobile ? [1, 1.1] : [1, 1],
    }).ref

    const parallaxRef3 = useParallax({
        easing: 'easeOutQuad',
        scale: isMobile ? [1, 1.1] : [1, 1],
    }).ref

    const toggleDrawer = () => {
        setDrawerOpen((prevState) => !prevState)
    }

    useEffect(() => {
        if (loadedImages === totalImages) {
            setLoading(false)
        }
    }, [loadedImages, totalImages])

    const handleImageLoad = () => {
        setLoadedImages((prev) => prev + 1)
    }

    useEffect(() => {
        if (!isTablet && !isMobile) {
            setDrawerOpen(false)
        }
    }, [isTablet, isMobile])

    const handleClick = () => {
        console.log('clicked')
        window.open(
            'https://medium.com/@beccablazzreese/blazzi-finding-the-perfect-nail-technician-has-never-been-easier-0dcb18024f7a',
            '_blank'
        )
    }

    return (
        <div className="about-page">
            {loading && <LoadingScreen />}
            <Navbar toggleDrawer={toggleDrawer} isHome={false} />
            <div
                className={`
                ${isTablet || isMobile ? 'mobile-block' : null} 
                ${drawerOpen ? 'drawer-open' : null}`}
            >
                <div className="about">
                    <div className={`about-intro ${isMobile && 'about-intro--mobile'}`}>
                        <div
                            className={`about-intro__content ${isMobile && 'about-intro__content--mobile'}`}
                        >
                            <h1 className="about-intro__title">About Blazzi</h1>
                            <p className={`about-intro__text ${isMobile && 'center-text'}`}>
                                Blazzi is a mobile app designed to connect nail technicians with
                                clients, making it easy for beauty professionals to{' '}
                                <strong>grow</strong> their clientele and for clients to{' '}
                                <strong>find</strong> skilled techs who meet their unique needs.
                            </p>
                        </div>
                        <div className="about-intro__image-container">
                            <img
                                className="about-intro__image"
                                src="/images/about-hands.jpg"
                                alt="Blazzi Logo"
                                ref={parallaxRef1}
                                onLoad={handleImageLoad}
                            />
                        </div>
                    </div>
                    <div
                        className={`about-intro ${isMobile ? 'about-intro--mobile' : 'about-intro__reorder'}`}
                    >
                        <div
                            className={`about-intro__content ${isMobile && 'about-intro__content--mobile'}`}
                        >
                            <p className={`about-intro__text ${isMobile && 'center-text'}`}>
                                With <strong>filtered search options</strong>, and transparent
                                pricing, Blazzi empowers new and seasoned nail technicians alike to
                                focus on their craft while managing their business efficiently.
                            </p>
                        </div>
                        <div className="about-intro__image-container">
                            <img
                                className="about-intro__image"
                                src="/images/about-nailpolish.jpg"
                                alt="Blazzi Logo"
                                ref={parallaxRef2}
                                onLoad={handleImageLoad}
                            />
                        </div>
                    </div>
                    <div className={`about-intro ${isMobile && 'about-intro--mobile'}`}>
                        <div
                            className={`about-intro__content ${isMobile && 'about-intro__content--mobile'}`}
                        >
                            <p className={`about-intro__text ${isMobile && 'center-text'}`}>
                                Built with both <strong>beauty professionals and clients</strong> in
                                mind, Blazzi is where beauty is truly bliss, not a hassle.
                            </p>
                        </div>
                        <div className="about-intro__image-container">
                            <img
                                className="about-intro__image"
                                src="/images/about-powder.jpg"
                                alt="Blazzi Logo"
                                ref={parallaxRef3}
                                onLoad={handleImageLoad}
                            />
                        </div>
                    </div>
                    <div className={`about-founder ${isMobile && 'about-founder--mobile'}`}>
                        <div className="about-founder__content">
                            <h1
                                className={`about-founder__title ${isMobile && 'about-founder__title--mobile'}`}
                            >
                                Meet the Founder
                            </h1>
                            <div
                                className={`about-founder__text-content ${isMobile && 'about-founder__text-content--mobile'}`}
                            >
                                <img
                                    className={`about-founder__image ${isMobile && 'about-founder__image--mobile'}`}
                                    src="/images/Rebecca_Profile.png"
                                    alt="Becca Reese"
                                    onLoad={handleImageLoad}
                                />
                                <div className="about-founder__text-container">
                                    <p className="about-founder__text">
                                        Becca Reese, the founder of Blazzi, is a tech enthusiast
                                        with a passion for empowering beauty professionals. With a
                                        background in data analytics and business intelligence,
                                        Becca holds a bachelor’s and master’s degree in Information
                                        Systems from the BYU Marriott School of Business.
                                    </p>
                                    <p className="about-founder__text">
                                        Becca's inspiration for Blazzi came from seeing the
                                        challenges her friend, a newly licensed nail technician,
                                        faced in building a clientele. She's dedicated to making
                                        beauty accessible, efficient, and enjoyable for both
                                        technicians and clients.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={isMobile ? 'about-mission--mobile' : 'about-mission'}>
                        <div
                            className={`about-mission__content ${isMobile ? 'about-mission__wide' : ''}`}
                        >
                            <h1
                                className={`about-mission__title ${isMobile ? 'about-mission__title--mobile' : null}`}
                            >
                                Blazzi's Mission
                            </h1>
                            <p
                                className={`about-mission__text ${isMobile ? 'about-mission__text--mobile' : null}`}
                            >
                                Blazzi's mission is to empower nail technicians to grow their
                                businesses with ease, connecting them directly to clients and
                                streamlining their bookings so beauty is always bliss, never a
                                struggle.
                            </p>
                            <Value
                                icon={faDumbbell}
                                value="Empowerment"
                                text="We aim to empower nail technicians to grow their businesses with ease"
                            />
                            <Value
                                icon={faRegularCircle}
                                value="Simplicity"
                                text="We believe beauty should be bliss, not complicated, so our platform is 
                                designed for a smooth and intuitive experience."
                            />
                            <Value
                                icon={faLayerGroup}
                                value="Transparency"
                                text="We prioritize clear, upfront communication about services and prices to 
                                build trust between clients and technicians."
                            />
                            <Value
                                icon={faLightbulb}
                                value="Innovation"
                                text="We continuously improve our platform with data-driven insights and 
                                features that help nail techs stay ahead in their industry."
                            />
                        </div>
                    </div>
                    <div className="about-early-success">
                        <div className="about-early-success__container">
                            <h1 className="about-early-success__title">
                                Read more about Blazzi’s Early Success
                            </h1>
                            <button className="about-early-success__button" onClick={handleClick}>
                                View on Medium
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

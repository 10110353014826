import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { useMediaQuery } from 'react-responsive'
import Drawer from '../components/Drawer'
import '../styles/Navbar.css'

export default function Navbar({ isHome }) {
    const [drawerOpen, setDrawerOpen] = useState(false)

    const isSmallScreen = useMediaQuery({ query: '(max-width: 964px)' })

    const toggleDrawer = () => {
        setDrawerOpen((prevState) => !prevState)
    }

    return (
        <>
            {isSmallScreen ? (
                <div className="navbar__container--mobile">
                    <div className="navbar--mobile">
                        <div className="navbar__bar--mobile">
                            <a href="/" className="navbar_home-link">
                                <img
                                    className="navbar__logo--mobile"
                                    src="/logos/Logo_Small_White_Outlined.png"
                                    alt="small Blazzi logo"
                                />
                            </a>
                            <nav>
                                <button className="navbar__dropdown-button" onClick={toggleDrawer}>
                                    <FontAwesomeIcon className="menu-icon--mobile" icon={faBars} />
                                </button>
                            </nav>
                        </div>
                    </div>
                    <Drawer drawerOpen={drawerOpen} />
                </div>
            ) : (
                <div className={`navbar ${isHome ? 'navbar--home' : null}`}>
                    <a className="navbar__home-link" href="/">
                        <img
                            className="navbar__logo"
                            src="/logos/Logo_Large_White.svg"
                            alt="Blazzi logo"
                        />
                    </a>
                    <nav className="navbar__nav">
                        <ul className="navbar__nav-list">
                            <li className="navbar__nav-item">
                                <a href="/" className="navbar__nav-link">
                                    Home
                                </a>
                            </li>
                            <li className="navbar__nav-item">
                                <a href="/about" className="navbar__nav-link">
                                    About
                                </a>
                            </li>
                            <li className="navbar__nav-item">
                                <a href="/nailTechs" className="navbar__nav-link">
                                    Nail Techs
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            )}
        </>
    )
}

import React from 'react'
import '../styles/Footer.css'

export default function Footer() {
    return (
        <div className="footer">
            <p className="footer__text">© 2023 Blazzi</p>
        </div>
    )
}

import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { ParallaxProvider } from 'react-scroll-parallax'
import Home from './pages/Home'
import NailTechs from './pages/NailTechs'
import About from './pages/About'
import './App.css'
import './styles/Theme.css'

function App() {
    return (
        <ParallaxProvider>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/nailTechs" element={<NailTechs />} />
                    <Route path="/about" element={<About />} />
                </Routes>
            </BrowserRouter>
        </ParallaxProvider>
    )
}

export default App

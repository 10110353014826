import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../styles/Value.css'

export default function Value({ icon, value, text }) {
    return (
        <div>
            <div className="value">
                <div className="value__icon-container">
                    <FontAwesomeIcon className="value__icon" icon={icon} />
                </div>
                <div className="value__text-container">
                    <h1 className="value__title">{value}</h1>
                    <p className="value__text">{text}</p>
                </div>
            </div>
        </div>
    )
}

import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { useMediaQuery } from 'react-responsive'
import LoadingScreen from '../components/LoadingScreen'
import '../styles/Home.css'

export default function Home() {
    const [drawerOpen, setDrawerOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const [loadedImages, setLoadedImages] = useState(0)

    const totalImages = 4

    const isTablet = useMediaQuery({ query: '(max-width: 964px)' })
    const isMobile = useMediaQuery({ query: '(max-width: 822px)' })

    const toggleDrawer = () => {
        setDrawerOpen((prevState) => !prevState)
    }

    useEffect(() => {
        if (!isTablet && !isMobile) {
            setDrawerOpen(false)
        }
    }, [isTablet, isMobile])

    useEffect(() => {
        if (loadedImages === totalImages) {
            setLoading(false)
        }
    }, [loadedImages, totalImages])

    const handleImageLoad = () => {
        setLoadedImages((prev) => prev + 1)
    }

    return (
        <>
            {loading && <LoadingScreen />}
            <Navbar toggleDrawer={toggleDrawer} isHome={true} />
            <div
                className={`
                page-content 
                ${isTablet || isMobile ? 'mobile-block' : null} 
                ${drawerOpen ? 'drawer-open' : null}`}
            >
                <div>
                    <div
                        className={`splash ${isTablet || isMobile ? 'splash__background--mobile' : null}`}
                    >
                        <img
                            className={
                                isTablet || isMobile
                                    ? 'splash__background-image splash__background--mobile'
                                    : 'splash__background-image'
                            }
                            src="/images/splash__background.png"
                            alt="Someone doing anothers nails"
                            onLoad={handleImageLoad}
                        />
                        <div
                            className={
                                isTablet || isMobile
                                    ? 'splash__background-overlay splash__background--mobile'
                                    : 'splash__background-overlay'
                            }
                        />
                        <img
                            src="/logos/Logo_Large_White.svg"
                            alt="Blazzi logo"
                            className="splash__title"
                        />
                        <h2 className="splash__subtitle">
                            Finding the perfect nail tech for you has never been easier
                        </h2>
                    </div>
                    <div className="app-promotion">
                        <div className="app-promotion__box">
                            <p
                                className={`app-promotion__text home-text ${isMobile && 'app-promotion__text--mobile'}`}
                            >
                                Finding a nail tech can be stressful or intimidating and many people
                                don’t know where to start
                            </p>
                            <p className="app-promotion__text--bold home-text--bold home-text--bold--mobile">
                                That’s where Blazzi steps in
                            </p>
                            <button
                                className={`app-promotion__button button-home ${isMobile && 'app-promotion__button--mobile'}`}
                            >
                                App coming soon
                            </button>
                        </div>
                    </div>
                    <div className="app-screens">
                        <div
                            className={`app-screens__container ${isMobile ? 'app-screens__container--mobile' : isTablet ? 'app-screens__container--table' : null}`}
                        >
                            <div
                                className={`app-screens__screen-container ${isMobile ? 'app-screens__screen-container--mobile' : null}`}
                            >
                                <div className="app-screens__screen">
                                    <img
                                        className={`app-screens__screen-image--small ${isMobile ? 'app-screens__screen-image--mobile' : null}`}
                                        src="/images/search-filters.png"
                                        alt="Blazzi app screen 1"
                                        onLoad={handleImageLoad}
                                    />
                                    <p
                                        className={`app-screens__screen-text ${isMobile ? 'app-screens__screen-text--mobile' : null}`}
                                    >
                                        Filter for your specific nail wants and needs
                                    </p>
                                </div>
                            </div>
                            <div
                                className={`app-screens__screen-container ${isMobile ? 'app-screens__screen-container--dark-mobile' : null}`}
                            >
                                <div className="app-screens__screen">
                                    <img
                                        className={`app-screens__screen-image--large ${isMobile ? 'app-screens__screen-image--mobile' : null}`}
                                        src="/images/client-map.png"
                                        alt="Blazzi app screen 2"
                                        onLoad={handleImageLoad}
                                    />
                                    <p
                                        className={`app-screens__screen-text ${isMobile ? 'app-screens__screen-text--mobile' : null}`}
                                    >
                                        Find individual nail artists local to you
                                    </p>
                                </div>
                            </div>
                            <div
                                className={`app-screens__screen-container ${isMobile ? 'app-screens__screen-container--mobile' : null}`}
                            >
                                <div className="app-screens__screen">
                                    <img
                                        className={`app-screens__screen-image--small ${isMobile ? 'app-screens__screen-image--mobile' : null}`}
                                        src="/images/services.png"
                                        alt="Blazzi app screen 3"
                                        onLoad={handleImageLoad}
                                    />
                                    <p
                                        className={`app-screens__screen-text ${isMobile ? 'app-screens__screen-text--mobile' : null}`}
                                    >
                                        See nail artist prices and policies before you pay
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}
